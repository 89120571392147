import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { CommonService } from '../common/common.service';
import { User } from '../../lib/User';
import { UserRole } from '../../lib/UserRole';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: User = {} as User;

    constructor(
        private httpService: DynamoHttpService,
        private commonService: CommonService,
    ) {}

    register(user: User): Observable<User> {
        return this.httpService.post<User>('cms', 'user', 'register', user);
    }

    login(user: User): void {
        this.logout();
        this.httpService
            .postExpectResponseType('cms', 'user', 'login', user, 'plaintext')
            .subscribe((token: string) => {
                if (!token) {
                    return;
                }
                this.authenticate(token);
                if (!this.commonService.IsPlatformBrowser()) {
                    return;
                }
                location.reload();
            });
    }

    getByAuthentication(): Observable<User> {
        return this.httpService.get<User>('cms', 'user', 'get');
    }

    authenticate(token?: string, skipRouting?: boolean): void {
        if (token) {
            this.saveToken(token);
        }
        this.getByAuthentication()
            .toPromise()
            .then((user: User | undefined) => {
                if (!user) {
                    return;
                }
                this.user = user;
                if (!skipRouting) {
                    this.commonService.navigate('/cms');
                }
            })
            .catch(() => {
                this.commonService.navigate('/login');
            });
    }

    logout(): void {
        this.removeToken();
        this.user = {} as User;
        this.commonService.navigate('/login');
    }

    saveToken(token: string): void {
        if (!this.commonService.IsPlatformBrowser()) {
            return;
        }

        localStorage.setItem('auth_tkn', token);
        this.getByAuthentication().subscribe((user: User): void => {
            this.user = user;
        });
    }

    removeToken(): void {
        if (!this.commonService.IsPlatformBrowser()) {
            return;
        }

        localStorage.setItem('auth_tkn', '');
    }

    getToken(): string | null {
        if (!this.commonService.IsPlatformBrowser()) {
            return null;
        }

        return localStorage.getItem('auth_tkn');
    }

    isAdmin(): boolean {
        return this.user?.role === UserRole.ADMIN;
    }

    getUsers(): Observable<User[]> {
        return this.httpService.get<User[]>('cms', 'user', 'get/all');
    }

    changePassword(value: { oldPassword: string; newPassword: string }): Observable<unknown> {
        return this.httpService.post('cms', 'user', 'password/update', value);
    }
}
