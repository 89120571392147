import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';
import { UserService } from '../services/user/user.service';
import { User } from '../lib/User';
import { DynamoFormComponent, DynamoFormControl, DynamoFormControlType, DynamoFormGroupModel } from '@skillgmbh/dynamo';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';

type UserFormGroup = FormGroup<GroupResolverFormBuilder<DynamoFormGroupModel>>;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit {
    @ViewChild('loginForm', { static: false }) loginForm: DynamoFormComponent;

    formModel: DynamoFormControl[] = [
        {
            name: 'username',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Benutzername',
        },
        {
            name: 'password',
            control: new FormControl('', [Validators.required]),
            formControlType: DynamoFormControlType.INPUT,
            labelText: 'Passwort',
            inputType: 'password',
        },
    ];

    constructor(private userService: UserService) {
        if (this.userService.getToken()) {
            this.userService.authenticate(undefined, false);
        }
    }

    ngAfterViewInit(): void {
        this.loginForm?.submitEvent?.subscribe((formGroup: UserFormGroup): void => {
            this.login(formGroup);
        });
    }

    buildUserObject(formGroup: UserFormGroup): User {
        if (!formGroup) {
            return;
        }

        return {
            username: formGroup.controls.username.value,
            password: formGroup.controls.password.value,
        } as User;
    }

    login(formGroup: UserFormGroup): void {
        this.userService.login(this.buildUserObject(formGroup));
    }
}
