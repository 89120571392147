<ng-container (keydown.enter)="login(loginForm.formGroup)">
    <span class="login__container"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8rem"
          style="color: #3a3873">
        <span>
            <dynamo-form
                #loginForm
                formTitle="Bei Dynamo CMS anmelden"
                submitButtonText="Anmelden"
                [data]="formModel">
            </dynamo-form>
        </span>
    </span>
</ng-container>
