import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DynamoListMenuComponent, DynamoListMenuIconLibrary } from '@skillgmbh/dynamo';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements AfterViewInit {
    readonly DynamoListMenuIconLibrary: typeof DynamoListMenuIconLibrary = DynamoListMenuIconLibrary;

    @ViewChild('settingsMenu') settingsMenu: DynamoListMenuComponent = {} as DynamoListMenuComponent;

    ngAfterViewInit(): void {
        this.settingsMenu?.selectByKey('SECURITY');
    }

    setSelectedSetting(key: string): void {
        if (key === undefined) {
            this.settingsMenu.selectByKey('SECURITY');
        }
    }
}
