import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DynamoFormComponent, DynamoFormControl, DynamoFormControlType } from '@skillgmbh/dynamo';
import { UserService } from '../../../../services/user/user.service';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';

@Component({
    selector: 'app-security-settings',
    templateUrl: './security-settings.component.html',
    styleUrls: ['./security-settings.component.scss'],
})
export class SecuritySettingsComponent implements AfterViewInit {
    @ViewChild('passwordForm') passwordForm: DynamoFormComponent = {} as DynamoFormComponent;
    public passwordFormControls: DynamoFormControl[] = [];

    constructor(private userService: UserService) {
        this.resetPasswordForm();
    }

    ngAfterViewInit(): void {
        this.passwordForm?.submitEvent?.subscribe((formGroup: FormGroup<any>): void => {
            this.userService
                .changePassword(formGroup.value as { newPassword: string; oldPassword: string })
                .subscribe((): void => {
                    this.resetPasswordForm();
                });
        });
    }

    resetPasswordForm(): void {
        this.passwordFormControls = [
            {
                name: 'oldPassword',
                labelText: 'Altes Passwort',
                formControlType: DynamoFormControlType.INPUT,
                inputType: 'password',
                control: new FormControl('', [Validators.required]),
            } as DynamoFormControl,
            {
                name: 'newPassword',
                labelText: 'Neues Passwort',
                formControlType: DynamoFormControlType.INPUT,
                inputType: 'password',
                control: new FormControl('', [Validators.required]),
            } as DynamoFormControl,
            {
                name: 'newPasswordCheck',
                labelText: 'Neues Passwort bestätigen',
                formControlType: DynamoFormControlType.INPUT,
                inputType: 'password',
                control: new FormControl('', [Validators.required]),
            } as DynamoFormControl,
        ];
    }
}
