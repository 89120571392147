<div class="cms-edit__view">
    <app-cms-edit-toolbar class="cms-edit__toolbar" (editToolbarToolClicked)="onEditToolbarToolClicked($event)">
    </app-cms-edit-toolbar>
    <ng-container *ngIf="commonService.IsPlatformBrowser() && source?.length">
        <gui-grid
            class="cms-edit__grid"
            [source]="source"
            [cellEditing]="true"
            [rowHeight]="isGroupHavingImages() ? 100 : undefined"
            (sourceEdited)="onSourceEdited($event.before, $event.after)">
            <gui-grid-column header="Bezeichnung" field="tag">
                <ng-template let-value="value">
                    {{ value }}
                </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Text" field="textContent">
                <ng-template let-value="value">
                    {{ value }}
                </ng-template>
            </gui-grid-column>
            <gui-grid-column
                *ngIf="isGroupHavingImages()"
                header="Bildvorschau"
                field="imageContent"
                [cellEditing]="false">
                <ng-template let-value="value">
                    <div
                        class="cms-edit__grid__column--image-preview__image"
                        style="background-image: url({{ value }})"></div>
                </ng-template>
            </gui-grid-column>
            <gui-grid-column *ngIf="isGroupHavingImages()" header="Aktionen" field="id" [cellEditing]="false">
                <ng-template let-value="value" let-i="index">
                    <button class="cms-edit__grid__column--actions__button--change-image">
                        <label [for]="'image-change-' + i">
                            <span>Bild ändern</span>
                        </label>
                    </button>
                    <input
                            class="cms-edit__grid__column--actions__input--change-image"
                            type="file"
                            accept="image/*"
                            [id]="'image-change-' + i"
                            (change)="onImageChangeButtonClicked(value, $event)" />
                </ng-template>
            </gui-grid-column>
        </gui-grid>
    </ng-container>
</div>
