<div class="dynamic__padding--md container">
    <span class="menu__container">
        <dynamo-list-menu
            #settingsMenu
            fxLayoutAlign="start start"
            fxLayout="column"
            (selectedOptionChange)="setSelectedSetting($event.key)"
            [listMenuConfig]="{
                listMenuOptions: [
                    {
                        key: 'SECURITY',
                        name: 'Sicherheit',
                        icon: {
                            type: 'radio_button_unchecked',
                            library: DynamoListMenuIconLibrary.MATERIAL
                        }
                    }
                ]
            }">
        </dynamo-list-menu>
    </span>
    <div class="content">
        <div *ngIf="settingsMenu.isSelected('SECURITY')">
            <app-security-settings></app-security-settings>
        </div>
    </div>
</div>
