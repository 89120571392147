import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { Category } from '../../../../lib/Category';
import { Entry } from '../../../../lib/Entry';
import { EntryService } from '../../../../services/entry/entry.service';
import { take } from 'rxjs';
import { CommonService } from '../../../../services/common/common.service';
import { NgIf } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { EntryType } from '../../../../lib/enum/EntryType';
import { CategoryService } from '../../../../services/category/category.service';
import { CmsEditToolbarComponent } from '../cms-edit-toolbar/cms-edit-toolbar.component';
import { EditToolbarTool } from '../../../../enums/EditToolbarTool';

@Component({
    standalone: true,
    selector: 'app-cms-edit',
    templateUrl: './cms-edit.component.html',
    styleUrls: ['./cms-edit.component.scss'],
    imports: [NgIf, GuiGridModule, CmsEditToolbarComponent],
})
export class CmsEditComponent implements OnInit, OnChanges {
    @Input() category!: Category;

    commonService: CommonService = inject(CommonService);

    source?: Entry[];

    private entryService: EntryService = inject(EntryService);
    private categoryService: CategoryService = inject(CategoryService);

    ngOnInit(): void {
        this.fetchEntries();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.source = [];

        if (changes.category) {
            this.fetchEntries();
        }
    }

    fetchEntries(): void {
        this.entryService
            .getAllByCategory(this.category)
            .pipe(take(1))
            .subscribe((entries: Entry[]): void => {
                /*
                Insert preview url of dynamo image into the imageContent attribute since
                its undefined and can be used as a placeholder for the grid column.
                 */
                entries.forEach((entry: Entry): void => {
                    console.log(entry.imageString);
                    entry.imageContent = `${environment._proxy_dynamo_cms}entry/get/image/preview/${entry.id}/M?hash${Math.random()}`;
                });

                // Sort alphabetically by entry tag.
                this.source = entries.sort((entryA: Entry, entryB: Entry): number => {
                    const tagA: string = entryA.tag.toLowerCase();
                    const tagB: string = entryB.tag.toLowerCase();

                    if (tagA < tagB) {
                        return -1;
                    }
                    if (tagA > tagB) {
                        return 1;
                    }
                    return 0;
                });
            });
    }

    onSourceEdited(entryBefore: Entry, entryAfter: Entry): void {
        // If entryAfter equals entryBefore it means it was not changed.
        if (entryAfter === entryBefore) {
            return;
        }

        // Re-fetching the entry that is requested to be saved because we changed its imageContent for display purposes.
        this.entryService
            .getById(entryAfter.id)
            .pipe(take(1))
            .subscribe((entry: Entry): void => {
                entryAfter.imageContent = entry.imageContent;

                // Entry was changed, save it.
                this.entryService.save(entryAfter).pipe(take(1)).subscribe();
            });
    }

    onImageChangeButtonClicked(entryId: number, event: Event): void {
        const newImageFile: File = (event.target as HTMLInputElement).files[0];

        if (!newImageFile) {
            return;
        }

        this.entryService
            .uploadFile(newImageFile, entryId)
            .pipe(take(1))
            .subscribe((): void => {
                this.fetchEntries();
            });
    }

    isGroupHavingImages(): boolean {
        return !!this.source.find((entry: Entry): boolean => entry.type === EntryType.IMAGE);
    }

    onEditToolbarToolClicked(editToolbarTool: EditToolbarTool): void {
        switch (editToolbarTool) {
            case EditToolbarTool.DELETE:
                this.deleteCategory();
        }
    }

    createNewEntry(): void {
        this.entryService.save({ tag: 'new', category: { id: this.category.id } }).subscribe((): void => {
            this.fetchEntries();
        });
    }

    deleteCategory(): void {
        if (!this.commonService.IsPlatformBrowser()) {
            return;
        }

        if (!confirm('Kategorie löschen?')) {
            return;
        }

        this.categoryService.delete(this.category).subscribe((): void => {
            // TODO: refetch categories
        });
    }
}
