import { Component, inject, OnInit } from '@angular/core';
import { DynamoHttpInstance, DynamoHttpService, DynamoIcon, DynamoIconService } from '@skillgmbh/dynamo';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    private httpService: DynamoHttpService = inject(DynamoHttpService);
    private iconService: DynamoIconService = inject(DynamoIconService);

    constructor() {
        this.httpService.registerInstance('cms', {
            url: environment._proxy_dynamo_cms,
            authorizationEnabled: true,
            storageTokenKey: 'auth_tkn',
        } as DynamoHttpInstance);

        this.registerDynamoIcon('delete', 'assets/icons/dynamo/delete.png');
    }

    private registerDynamoIcon(type: string, source: string): void {
        this.iconService.registerDynamoIcon({
            type,
            source,
        } as DynamoIcon);
    }
}
