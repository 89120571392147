import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-element-navigation',
    templateUrl: './element-navigation.component.html',
    styleUrls: ['./element-navigation.component.scss'],
})
export class ElementNavigationComponent {
    constructor(public router: Router) {}
}
