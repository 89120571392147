import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common/common.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'element-app-navigation',
    templateUrl: './app-navigation.component.html',
    styleUrls: ['./app-navigation.component.scss', '../element-navigation.component.scss'],
})
export class ElementAppNavigationComponent implements OnInit {
    constructor(
        public commonService: CommonService,
        public userService: UserService,
        public router: Router,
    ) {}

    ngOnInit(): void {
        if (this.userService.getToken()) {
            this.userService.authenticate(undefined, false);
        }
    }

    isAdmin(): boolean {
        return this.userService.isAdmin();
    }
}
