import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CmsComponent } from './components/cms/cms.component';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ElementHeaderComponent } from './elements/element-header/element-header.component';
import { ElementNavigationComponent } from './elements/element-navigation/element-navigation.component';
import { ElementAppNavigationComponent } from './elements/element-navigation/app-navigation/app-navigation.component';
import { MatTableModule } from '@angular/material/table';
import { SettingsComponent } from './components/settings/settings.component';
import { SecuritySettingsComponent } from './components/settings/components/security.settings/security-settings.component';
import { CmsSidebarComponent } from './components/cms/components/cms-sidebar/cms-sidebar.component';
import { DynamoModule } from '@skillgmbh/dynamo';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { routeName: 'Login' },
    },
    {
        path: 'cms',
        component: CmsComponent,
        data: { routeName: 'CMS' },
    },
    {
        path: 'settings',
        component: SettingsComponent,
        data: { routeName: 'settings' },
    },
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ElementHeaderComponent,
        ElementNavigationComponent,
        ElementAppNavigationComponent,
        SettingsComponent,
        SecuritySettingsComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        RouterModule.forRoot(appRoutes, {}),
        FlexLayoutModule,
        HttpClientModule,
        FormsModule,
        MatSelectModule,
        MatSliderModule,
        MatMenuModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        DynamoModule,
        MatTableModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
