<ng-container>
		<div class="cms-sidebar__view dynamic__stretch--width">
				<div class="cms-sidebar__header dynamic__stretch--width">
						<dynamo-image
								height="6rem"
								[config]="{type: DynamoImageType.IMAGE, content: 'assets/cms/sidebar/header/cms-logo-transparent.png'}">
						</dynamo-image>
						<span class="cms-sidebar__header__logout-button dynamic__text--xs"
						      (click)="userService.logout()">Abmelden</span>
				</div>
				<span class="dynamic__divider--horizontal"></span>
				<div class="cms-sidebar__nav dynamic__stretch--width">
						<dynamo-list-menu (selectedOptionChange)="onGroupChanged($event.key)"
						                  [listMenuConfig]="{
                            listMenuOptions: this.groupListMenuOptions
                          }">
						</dynamo-list-menu>
						<ng-container *ngIf="categoryListMenuOptions.length">
								<span class="dynamic__divider--horizontal"></span>
								<dynamo-list-menu (selectedOptionChange)="onCategoryChanged($event.key)"
								                  [listMenuConfig]="{
                            listMenuOptions: this.categoryListMenuOptions
                          }">
								</dynamo-list-menu>
						</ng-container>
				</div>
		</div>
</ng-container>
