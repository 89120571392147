import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Group } from '../../lib/Group';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { User } from '../../lib/User';

@Injectable({
    providedIn: 'root',
})
export class CategoryGroupService {
    public groups: Group[] = [];

    constructor(private httpService: DynamoHttpService) {}

    save(categoryGroup: Group, controlledUser: User): Observable<Group> {
        if (controlledUser) {
            categoryGroup.user = controlledUser;
            return this.httpService.post<Group>('cms', 'category-group', 'save/admin', categoryGroup);
        }
        return this.httpService.post<Group>('cms', 'category-group', 'save', categoryGroup);
    }

    delete(categoryGroup: Group, controlledUser: User): Observable<HttpResponse<unknown>> {
        if (controlledUser) {
            categoryGroup.user = controlledUser;
            return this.httpService.post<HttpResponse<unknown>>('cms', 'category-group', 'delete/admin', categoryGroup);
        }
        return this.httpService.post<HttpResponse<unknown>>('cms', 'category-group', 'delete', categoryGroup);
    }

    getAll(controlledUser?: User): Observable<Group[]> {
        if (controlledUser) {
            return this.httpService.post<Group[]>('cms', 'category-group', 'get/all/admin', controlledUser);
        }
        return this.httpService.post<Group[]>('cms', 'category-group', 'get/all', {});
    }

    getById(id: number): Observable<Group> {
        return this.httpService.post<Group>('cms', 'category-group', 'get/id', { id });
    }
}
