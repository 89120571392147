import { Injectable } from '@angular/core';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { Category } from '../../lib/Category';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Entry } from '../../lib/Entry';
import { User } from '../../lib/User';
import { EntryType } from '../../lib/enum/EntryType';

@Injectable({
    providedIn: 'root',
})
export class EntryService {
    constructor(private httpService: DynamoHttpService) {}

    save(entry: Entry, controlledUser?: User): Observable<Entry> {
        if (controlledUser) {
            return this.httpService.post<Entry>('cms', 'entry', 'save/admin', entry);
        }
        return this.httpService.post<Entry>('cms', 'entry', 'save', entry);
    }

    delete(entry: Entry): Observable<HttpResponse<unknown>> {
        return this.httpService.post<HttpResponse<unknown>>('cms', 'entry', 'delete', entry);
    }

    uploadFile(file: File, entryId: number, controlledUser?: User): Observable<HttpResponse<unknown>> {
        const uploadImageData: FormData = new FormData();

        uploadImageData.append('file', file);
        uploadImageData.append('entryId', entryId.toString());

        if (controlledUser) {
            return this.httpService.post<HttpResponse<unknown>>('cms', 'entry', 'upload/admin', uploadImageData);
        }
        return this.httpService.post<HttpResponse<unknown>>('cms', 'entry', 'upload', uploadImageData);
    }

    getAllByCategory(category: Category): Observable<Entry[]> {
        return this.httpService.post<Entry[]>('cms', 'entry', 'get/all/category', category);
    }

    getAllByTags(categoryGroupTag: string, categoryTag: string, entryTag: string): Observable<Entry[]> {
        return this.httpService.post<Entry[]>('cms', 'entry', 'get/tags', {
            categoryGroupTag,
            categoryTag,
            entryTag,
        });
    }

    getAllByTagsAndEntryType(categoryGroupTag: string, categoryTag: string, entryType: EntryType): Observable<Entry[]> {
        return this.httpService.post<Entry[]>('cms', 'entry', 'get/type', {
            categoryGroupTag,
            categoryTag,
            entryType,
        });
    }

    getById(id: number): Observable<Entry> {
        return this.httpService.post<Entry>('cms', 'entry', 'get/id', { id });
    }
}
