import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Environment } from '@angular/cli/lib/config/workspace-schema';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(
        private router: Router,
        @Optional() @Inject(PLATFORM_ID) private platformId: string,
    ) {}

    IsPlatformBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    environment(): { production: boolean; _proxy_dynamo_cms: string } {
        return environment;
    }

    navigate(route: string): void {
        void this.router.navigateByUrl(route);
    }

    isRouteActive(route: string): boolean {
        return this.router.isActive(route, true);
    }
}
