import { Component, EventEmitter, Output } from '@angular/core';
import { DynamoModule } from '@skillgmbh/dynamo';
import { EditToolbarTool } from '../../../../enums/EditToolbarTool';

@Component({
    standalone: true,
    selector: 'app-cms-edit-toolbar',
    templateUrl: './cms-edit-toolbar.component.html',
    styleUrls: ['./cms-edit-toolbar.component.scss'],
    imports: [DynamoModule],
})
export class CmsEditToolbarComponent {
    readonly EditToolbarTool: typeof EditToolbarTool = EditToolbarTool;

    @Output() editToolbarToolClicked: EventEmitter<EditToolbarTool> = new EventEmitter<EditToolbarTool>();

    onEditToolbarToolClicked(toolbarTool: EditToolbarTool): void {
        this.editToolbarToolClicked.emit(toolbarTool);
    }
}
