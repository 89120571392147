<ng-container>
    <section class="navigation__app"
             fxLayout="row wrap"
             fxLayoutAlign="start center"
             fxLayoutGap=".5rem">
        <div class="navigation-category"
             fxLayoutAlign="center center"
             routerLink="/management">
            <button class="navigation-button"
                    mat-button
                    fxLayoutAlign="center center">
                <mat-icon>space_dashboard</mat-icon>
                Dashboard
            </button>
        </div>
        <div class="navigation-category"
             fxLayoutAlign="center center"
             routerLink="/settings">
            <button class="navigation-button"
                    mat-button
                    fxLayoutAlign="center center">
                <mat-icon>settings</mat-icon>
                Einstellungen
            </button>
        </div>
        <div *ngIf="this.isAdmin()" class="navigation-category"
             fxLayoutAlign="center center"
             routerLink="/admin">
            <button class="navigation-button"
                    mat-button
                    fxLayoutAlign="center center">
                <mat-icon>admin_panel_settings</mat-icon>
                Administration
            </button>
        </div>
    </section>
</ng-container>
