<ng-container>
    <section class="header__container"
             fxLayout="column"
             fxLayoutAlign="center start">
        <div fxLayout="row"
             fxLayoutAlign="start center">
<!--            <img class="header__image"-->
<!--                 src="../../../assets/logos/service-logo.png"-->
<!--                 routerLink="/">-->
            <span class="dynamic__title--md"><b>SkillAgentur Dynamo CMS</b></span>
            <mat-divider [vertical]="true"
                         fxShow="true"
                         style="color: rgba(0, 0, 0, .3) ;margin-left: .4rem !important; margin-right: 1.3rem; width: 5px; height: 2rem; padding-left: .5rem !important;"></mat-divider>
            <app-element-navigation class="navigation">
            </app-element-navigation>
        </div>
    </section>
</ng-container>
