import { Injectable } from '@angular/core';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { CommonService } from '../common/common.service';
import { Category } from '../../lib/Category';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Group } from '../../lib/Group';
import { User } from '../../lib/User';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    public categories: Category[] = [];

    constructor(
        private httpService: DynamoHttpService,
        private commonService: CommonService,
    ) {}

    save(category: Category, controlledUser?: User): Observable<Category> {
        if (controlledUser) {
            return this.httpService.post<Category>('cms', 'category', 'save/admin', category);
        }
        return this.httpService.post<Category>('cms', 'category', 'save', category);
    }

    delete(category: Category, controlledUser?: User): Observable<HttpResponse<unknown>> {
        if (controlledUser) {
            return this.httpService.post<HttpResponse<unknown>>('cms', 'category', 'delete/admin', category);
        }
        return this.httpService.post<HttpResponse<unknown>>('cms', 'category', 'delete', category);
    }

    getAllByGroup(categoryGroup: Group, controlledUser?: User): Observable<Category[]> {
        if (controlledUser) {
            return this.httpService.post<Category[]>('cms', 'category', 'get/all/group/admin', categoryGroup);
        }
        return this.httpService.post<Category[]>('cms', 'category', 'get/all/group', categoryGroup);
    }

    getAllByGroupTag(categoryGroup: Group, tag: string): Observable<Category[]> {
        return this.httpService.post<Category[]>('cms', 'category', 'get/group/tag', {
            categoryGroup,
            tag,
        });
    }

    getById(id: number): Observable<Category> {
        return this.httpService.post<Category>('cms', 'category', 'get/id', { id });
    }
}
